import React from "react";
import "./portfolio.css";
/*import MoodTrackerImg from '../../assets/MoodTrackerImg.jpg'*/
import ServerImg from "../../assets/ServerImg.png";
import MoodImg from "../../assets/mood.png";
import TourImg from "../../assets/AddIncome.jpg"
import TourReportsImg from "../../assets/Reports.jpg"
import FinancialsImg from "../../assets/TourFinancials.jpg"
import EventsImg from "../../assets/AddEvents.jpg"
import ImageSlider from "../ImageSlider";

const slides = [TourImg, EventsImg, FinancialsImg, TourReportsImg]

const Portfolio = () => {
  return (
    <section id="portfolio" className="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className = "portfolio__item__main__container">
      <article className="portfolio__item__main">
          <h3>Tour Manager</h3>
          <div className = "portfolio__item-image-container__main">
            <ImageSlider slides = {slides}/>
            {/**
          <div className="portfolio__item-image__main">
            <img src={TourImg} alt="" />
          
          </div>
          <div className="portfolio__item-image__main">
  
            <img src={TourReportsImg} alt="" />
          </div>
           */}
          </div>
          
          <div className = "portfolio__item-desc__container">
          <div className="portfolio__item-desc">
            <p>Custom web application designed for Seattle-based rock band</p>
            <ul className="portfolio__item-list">
              <li>NextJS Framework: Typescript, React, Tailwind CSS, Prisma / MySQL</li>
              <li>Equips users with comprehensive financial management features</li>
              <li>Streamlines tour scheduling and provides easy access to historical data.</li>
              <li>Generates reports based on tour, event or time window to offer data-driven insights </li>
        
            </ul>
          </div>
          <div className = "portfolio__item__quote">
            <p>"I love that different band members can make updates and I can see our tour progress in real time!" - John M, Dead Bars</p>
          </div>
          </div>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/bbrickey/TourManager"
              className="btn"
              target="_blank"
            >
              Github
            </a>
            <a
              href="https://deadbarstourmanager.com"
              className="btn btn-primary"
              target="_blank"
            >
              Demo
            </a>
          </div>
        </article>
        </div>
      <div className="container portfolio__container">
       
        <article className="portfolio__item">
          <h3>Mood Tracker</h3>
          <div className="portfolio__item-image">
            <img src={MoodImg} loading = "lazy" alt="" />
          </div>
          <div className="portfolio__item-desc">
            <p>Web application to track daily mood and performance metrics</p>
            <ul className="portfolio__item-list">
              <li>Javascript, React, Node.js, SQL</li>
              <li>
                Integrates with Chess.com's public API to track and analyze
                chess performance, correlating it with user-provided mood inputs
              </li>
              <li>
                Retrieves user input data from a SQL database and generates
                summary reports for users.
              </li>
            </ul>
          </div>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/bbrickey/moodtracker/"
              className="btn"
              target="_blank"
            >
              Github
            </a>
            <a
              href="https://www.loom.com/share/3866c53d64d24af2b54d4b3b0c9f5c5a"
              className="btn btn-primary"
              target="_blank"
            >
              Demo
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <h3>Web Server</h3>
          <div className="portfolio__item-image">
            <img src={ServerImg} loading = "lazy" alt="" />
          </div>
          <div className="portfolio__item-desc">
            <p>Working web server that accepts and handles client requests</p>
            <ul className="portfolio__item-list">
              <li>POSIX, C++</li>
              <li>
                Utilizes multithreaded processing to improve efficiency and
                performance.
              </li>
              <li>
                Processes larger files by employing dynamic content generation
                and virtual memory mapping.
              </li>
            </ul>
          </div>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/bbrickey/webserver"
              className="btn"
              target="_blank"
            >
              Github
            </a>
            <a
              href="https://www.loom.com/share/e99d92c809d34497a6296f416a9f9c53"
              className="btn btn-primary"
              target="_blank"
            >
              Demo
            </a>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Portfolio;
