import React from 'react'
import './footer.css'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
        <h1 className = 'footer__logo'>Ben Brickey</h1>
        <ul className = 'permalinks'>
            <li><a href="#">Home</a></li>
            <li><a href="#portfolio">Portfolio</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#contact">Contact</a></li>
        </ul>

        <div className="footer__socials">
            <a href="https://www.linkedin.com/in/benjaminbrickeyiii/" target = "_blank"><BsLinkedin /></a>
            <a href="https://github.com/bbrickey" target = "_blank"><BsGithub /></a>
        </div>
        <div className="footer__copywright">
            <small>&copy; Benjamin Brickey 2023</small>
        </div>
    </footer>
  )
}

export default Footer