import React from 'react'
import './contact.css'
import {FiMail} from 'react-icons/fi'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import emailjs from 'emailjs-com'
import {useRef} from 'react';
import {useState} from 'react';

const Contact = () => {
  const [error, setError] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();


  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    

  
    emailjs.sendForm('service_tccubja', 'template_z2pijos', form.current, 'LG7okKESpR1JBQCu3')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    

      alert("Your message has been sent!")
      e.target.reset();
  };
  return (
    <section id = 'contact'>
    
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <FiMail className="contact__option-icon" />
            <a href="mailto:benjaminbrickeyiii@gmail.com" target = "_blank" >benjaminbrickeyiii@gmail.com</a>
          </article>
          <article className="contact__option">
            <BsLinkedin className="contact__option-icon" />
            <a href="https://www.linkedin.com/in/benjaminbrickeyiii/" target = "_blank" >LinkedIn</a>
          </article>
          <article className="contact__option">
            <BsGithub className="contact__option-icon" />
            <a href="https://github.com/bbrickey" target = "_blank" >Github</a>
          </article>
        </div>
        <form ref={form} onSubmit ={sendEmail}>
          <input type="text" name = 'name' placeholder = 'Your Full Name' required/>
          <input type="email" name = 'email' placeholder = 'Your Email' required/>
          <textarea name = "message" rows = "7" placeholder = 'Your Message' required />
          <button type = 'submit' className = 'btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact