import {useState} from 'react'
import {ArrowBigLeft, ArrowBigRight} from 'lucide-react'

const ImageSlider = ({slides}) => {
    const [imageIndex, setImageIndex] = useState(0)
    function showNextImage() {
        setImageIndex(index => {
            if (index === slides.length-1) return 0;
            return index + 1
        })
    }
    function showPrevImage() {
        setImageIndex(index => {
            if (index === 0) return slides.length - 1;
            return index - 1
        })
    }

  return (
    <div className = "image-slider__container">
        <div style = {{width: "100%", height: "100%", display: "flex", overflow: "hidden"}}>
            {slides.map(slide => (
                <img key = {slide} className = "image-slider__img" src = {slide} 
                style = {{translate: `${-100 * imageIndex}%`}} loading = "lazy"/>
            ))}
        </div>
        {/* <img className = "image-slide__img" src = {slides[imageIndex]} />  */}
        <button onClick = {showPrevImage} className = "image-slider__btn" style = {{left: 0}}><ArrowBigLeft /></button>
        <button onClick = {showNextImage} className = "image-slider__btn" style = {{right: 0}}><ArrowBigRight /></button>
    </div>
  )
}

export default ImageSlider