import React from 'react'
import './header.css'
import CTA from './CTA'
import HeaderSocials from './HeaderSocials'
import headshot from "../../assets/linkedin.jpg";
import guitar from "../../assets/guitar.jpg";

const Header = () => {
  return (
    <header className = "header">
      <div className="container header__container">
        {/*<h5>Greetings!</h5>*/}
        <h1>Ben Brickey</h1>
        <h5 className = "text-light">Full Stack Developer</h5>
        
      
  
        <div className = "me">
          <img loading = "lazy" src = {headshot} alt = "" />
          <CTA />
        </div>
        <HeaderSocials />
      </div>
    </header>
  )
}

export default Header