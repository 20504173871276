import React from "react";
import "./about.css";
import { GrPersonalComputer } from "react-icons/gr";
import { DiJavascript1 } from "react-icons/di";
import guitar from "../../assets/guitar.jpg";

const About = () => {
  return (
    <section id="about">
      
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img loading = "lazy" src={guitar}></img>
          </div>
        </div>

        <div className="about__content">
          {/*
          <div className="about__cards">
            <article className="about__card">
              <GrPersonalComputer className="about__icon" />
              <h5>Experience</h5>
              <small>3+ Years Working</small>
            </article>
            <article className="about__card">
              <DiJavascript1 className="about__icon" />
              <h5>Technologies</h5>
              <small>Javascript, React, SQL, Java, C++</small>
            </article>
            <article className="about__card">
              <GrPersonalComputer className="about__icon" />
              <h5>Projects</h5>
              <small>3+ Years Working</small>
            </article>
          </div>
  */}
          <p>
            I am a dedicated full-stack developer with a strong passion for
            fostering meaningful relationships and creating impactful tools for
            both internal and external customers. Drawing from my previous
            experience as a program manager, I excel in collaborating with
            cross-functional teams, including product, sales, and data science,
            to thoroughly understand customer challenges and deliver innovative
            solutions. Outside of my professional pursuits, I indulge in my
            creative side by making music, diving into books, and engaging in
            thought-provoking discussions on film and philosophy with friends
          </p>
          {/*
          <a href="#contact" className="btn btn-primary">
            Contact Me
          </a>
  */}
        </div>
      </div>
    </section>
  );
};

export default About;
